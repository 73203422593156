@charset "UTF-8";
@import "./utils/constants";


body, html {
  background: rgb(236, 236, 243) !important;
  font-family: Arial, sans-serif;
}

.app {
  max-width: 650px;
  margin: 0 auto;
}

:root:root {
  --adm-color-border: #979797;
  --adm-color-text: rgba(0, 0, 0, .87);
  --adm-color-light: rgba(0, 0, 0, .38);
}